<template>
  <modal name="cExtract" height="auto" width="900" :scrollable="true" :clickToClose="false">
    <CloseModal :close="close" />
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab :title="item.nome">
            <div class="form">
              <div class="xrow" style="margin-bottom: 10px">
                <div class="col-1-4">
                  <label>De</label>
                  <datetime
                    input-class="form-control"
                    v-model="dataInicio"
                    type="date"
                    :max-datetime="fim"
                    placeholder="Data inicial"
                    :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                    value-zone="local"
                    format="dd/MM/yyyy"
                  />
                </div>
                <div class="col-1-4">
                  <label>Até</label>
                  <datetime
                    input-class="form-control"
                    v-model="dataFim"
                    type="date"
                    :min-datetime="inicio"
                    placeholder="Data final"
                    :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                    value-zone="local"
                    format="dd/MM/yyyy"
                  />
                </div>
                <div class="col-1-4">
                  <label>&nbsp;</label><br>
                  <button class="button button-primary" v-on:click="findExtrato">
                    Buscar
                  </button>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Data Hora</th>
                      <th>Valor Anterior</th>
                      <th>Valor Novo</th>
                      <th>Modificador</th>
                      <th>Pedido</th>
                      <th>Negociação</th>
                      <th>Motivo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" v-bind:key="item.datahora_alteracao">
                      <td>{{ convertDataHora(item.datahora_alteracao) }}</td>
                      <td>{{ toMonetary(item.valor_disponivel_antigo) }}</td>
                      <td>{{ toMonetary(item.valor_disponivel_novo) }}</td>
                      <td>{{ item.colaborador_nome_alteracao }}</td>
                      <td>{{ item.pedido_id }}</td>
                      <td>{{ item.negociacao_id }}</td>
                      <td>{{ item.motivo + ' - ' + item.tipo}}</td>
                      <td v-if="item.valor_disponivel_antigo < item.valor_disponivel_novo">
                        <span class="label success">
                          <fa-icon icon="chevron-up" />
                        </span>                      
                      </td>
                      <td v-if="item.valor_disponivel_antigo > item.valor_disponivel_novo">
                        <span class="label error" title="Entrega">
                          <fa-icon icon="chevron-down" />
                        </span>                      
                      </td>                    
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </CTab>
        </CTabs>
      </div>
    </div>
  </modal>
</template>

<script>
import CloseModal from "../../components/CloseModal";
import { get } from "../../helpers/apiRequest";
import moment from 'moment';
import { toMonetary } from '@/helpers/utils';

export default {
  name: "cExtract",
  components: { CloseModal },
  props: ["item", "close"],
  data() {
    return {
      items: [],
      dataInicio: moment()
        .subtract(1, 'months')
        .format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
    };
  },  
  computed: {
    inicio() {
      return moment(this.dataInicio).format('YYYY-MM-DD');
    },

    fim() {
      return moment(this.dataFim).format('YYYY-MM-DD');
    },
  },
  watch: {
    "item.colaborador_id"() {      
        this.findExtrato()      
    },
  },
  methods: {
    findExtrato() {      
      get(`/admin/conta-corrente/${this.item.colaborador_id}/extrato?data_inicio=${this.inicio}&data_fim=${this.fim}`)      
      .then((json) => {        
        this.items = json;        
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
      });
    },
    convertDataHora(dataHora) {
      return moment(String(dataHora)).format('DD/MM/YYYY HH:mm')
    },
    beforeMount() {
      this.loading = true;    
    },
    toMonetary
  },
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.pre-text {
  font-size: 10px;
}
.main-text {
  font-size: 14px;
  font-weight: bold;
}
.details {
  background-color: #fafafa;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
</style>
